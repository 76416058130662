<template>
    <div>
        <template v-if="isAuthenticated">
            <template v-if="checkWishlistStatus && !status">
                <div class="mt-2 wishlist-link cursor-pointer w-100" >
                    <a @click="callRemoveWishlistPromise(getSelecteItem)" > 
                        <b-spinner v-if="wishBtnLoading" small></b-spinner> 
                        <span v-if="!wishBtnLoading" class="icon-wishlist-selected"> </span>
                        <span> Remove Wishlist</span>
                    </a>
                </div>
            </template>
            <template v-else >
                <div v-if="!status" class="mt-2 wishlist-link cursor-pointer w-100" >
                    <a @click="addWishlist(itemData)" > 
                        <b-spinner v-if="wishBtnLoading" small></b-spinner> 
                        <span v-if="!wishBtnLoading" class="icon-wishlist"></span> 
                        <span> Add to Wishlist</span>
                    </a>
                </div>
            </template>
        </template>
        <template v-else>
            <div class="mt-2 wishlist-link cursor-pointer w-100" > 
                <a v-b-modal.authenticate @click="actionBtn"> 
                    <span class="icon-wishlist"></span>
                    <span>Add to Wishlist</span>
                </a>
            </div>
        </template>
    </div>
</template>

<script>
import store from "@/store"
import { mapActions } from 'vuex'
import Helper from "@/services/helper"
export default {
    name: "ActionWishlist",
    props:{
        itemData:{
            required: true,
            type:[Array,Object],
        }
    },
    data() {
        return {
            status: this.itemData.purchase,
            isAuthenticated: store.getters.authenticated,
            reqItem:{
                user_id: this.itemData.user_id,
                item_id: this.itemData.item_id,
                item_type: this.itemData.item_type,
                quantity: this.itemData.quantity
            },
            actionType:"",
            wishBtnLoading:false,
        };        
    },
    computed: {
        checkWishlistStatus(){
            let app = this;
            let wishlistItems =  store.getters.wishlistItems;
            return Helper.checkCurrentWishlistStatus(wishlistItems , app.itemData.item_id);
        },
        getSelecteItem(){
            let app = this;
            let wishlistItems = store.getters.wishlistItems;
            return Helper.getSelectWishlistItem(wishlistItems, app.itemData.item_id);
        },
    },
    mounted(){
        let app = this;
        app.$root.$on("authentication-status", ($event) => {
            app.isAuthenticated = $event;
        });
    },
    methods:{
        ...mapActions(["addWishlistItem","removeWishlistItem"]),
        addWishlist(cartItem){
            let app = this;
            app.wishBtnLoading = true;
            app.addWishlistItem(cartItem).then(() => {
                app.wishBtnLoading = false;
            });
        },
        callRemoveWishlistPromise(item){
            let app = this;
            app.wishBtnLoading = true;
            app.removeWishlistItem({user_id:app.itemData.user_id, item_id:item.item_id, item_type:item.item_type }).then(() => {
                app.wishBtnLoading = false;
            });
        },
        actionBtn(){
            this.actionType = "add-to-wishlist";
        }
    },
    watch: {
        itemData(value){
            let app = this;
            app.reqItem.item_id     = value.item_id;
            app.reqItem.item_type   = value.item_type;
            app.reqItem.quantity    = value.quantity;
            app.reqItem.user_id     = value.user_id;
            app.status  = value.purchase;
        },
        isAuthenticated(value) {
            let app = this;
            if(value && app.actionType == 'add-to-wishlist') {
                app.reqItem.user_id = store.getters.user.id;
                app.addWishlistItem(app.reqItem);
            }
        },
    },
}
</script>