<template>
    <section class="shopstuff-wrap py-5" v-if="assets.data.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title">
                        <div>
                            <h2>{{title}}</h2>
                            <div v-html="description"></div>
                        </div>
                        <router-link :to="{ name: 'AssetsList'}"> View All <span class="icon-arrow"></span> </router-link>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-3" v-for="(item,i) in assets.data" :key="i" >
                    <div class="shop-block" @click="quickView(item)" >
                        <AssetItem :item="item" />
                    </div>
                </div>
            </div>
        </div>
        <AssetViewModal :assetItem="modalData" />
    </section>
</template>
<script>

import axios from 'axios'
import api from '@/services/api'
import AssetItem from '@/components/AssetItem'
import AssetViewModal from '@/components/AssetViewModal'
export default {
    name: "ShopStuff",
    components: { 
        AssetItem,AssetViewModal
    },
    data() {
        return {
            title:"",
            description:"",
            assets: { 
                data: [],
            },
            modalData:[]
        };        
    },
    mounted() {
        let app = this;
        app.renderItems();
    },
    methods: {
        async renderItems(){
            let app = this;
            await axios.get(api.getUrl('/home/assets')).then(function (response) {
                if(response.data.success){
                    let responseData    = response.data.data;
                    app.title       = responseData.title;
                    app.description = responseData.description;
                    app.assets.data     = responseData.data;
                }
            });
        },
        quickView(item) {
            let app = this;
            app.$bvModal.show("AssetViewModal");
            app.modalData = item;
        },
    }
}
</script>