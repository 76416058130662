<template>
    <div>
        <img :src="itemImages" class="img-fluid" alt="shop assets">
        <h3>{{itemTitle}}</h3>
        <template v-if="isOffer">
            <span class="shopPrice"><del>{{itemPriceDisplay}}</del></span>
            <span class="shopPrice">{{ itemOfferPriceDisplay }}</span>
        </template>
        <template v-else>
            <span v-if="price == 0" class="course-price"> Free </span>
            <span v-else class="course-price">{{ itemPriceDisplay }} </span>
        </template>

        <div class="quickBuy" v-b-tooltip.hover title="Quick View">
            <span class="icon-view"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "AssetItem",
    props:{
        item:{
            required: true,
        }
    },
    data(){
        return {
            itemTitle:this.item.title,
            itemImages: this.item.image,
            itemPriceDisplay:this.item.display_price,
            price:this.item.price,

            isOffer:this.item.is_offer,
            itemOffer:this.item.offer_percentage,
            itemOfferPriceDisplay:this.item.display_offer_price
        }
    },
    watch: {
        item(value) {
            let app = this;
            app.itemTitle  =  value.title,
            app.itemImages =  value.image;
            app.itemPriceDisplay = value.display_price,
            app.price = value.price,

            app.isOffer    = value.is_offer,
            app.itemOffer  = value.offer_percentage,
            app.itemOfferPriceDisplay = value.display_offer_price
        },
    },

}
</script>